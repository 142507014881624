.cdk-overlay-pane {
    // On mobile, the pane will be rendered at the bottom of the screen
    @apply max-w-[100vw] md:max-w-[80vw] max-sm:absolute bottom-0 #{!important};

    // mat-dialog rounded borders, both on desktop and mobile
    mat-dialog-container {
        .mdc-dialog__container {
            .mdc-dialog__surface {
                @apply rounded-t-2xl md:rounded-xl #{!important};
            }
        }
    }
}

body:has(> .cdk-overlay-container:not(:empty)) {
    @apply overflow-y-hidden #{!important};

    mat-bottom-sheet-container {
        .hidden-when-in-sheet {
            display: none !important;
        }
    }
}

.cdk-overlay-pane.quick-measure-panel {
    mat-bottom-sheet-container {
        @apply overflow-hidden rounded-t-lg;
    }
}

.wrapper {
    @apply h-full;
}

// To display quick measurements on mobile
app-root.quick-measuring {
    .wrapper {
        @apply absolute inset-0 h-[calc(100vh-64px)] block #{!important};
    }

    .document-navbar {
        @apply bottom-16 #{!important};
    }

    .navbar-spacer {
        @apply h-[162px] #{!important};
    }

    // FIXME: Manage this in a better way
    .group-content {
        height: calc(100vh - 124px);
    }
}
