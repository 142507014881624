.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: rgba(14, 114, 166, 1);
    --mdc-checkbox-selected-hover-icon-color: rgba(14, 114, 166, 1);
    --mdc-checkbox-selected-icon-color: rgba(14, 114, 166, 1);
    --mdc-checkbox-selected-pressed-icon-color: rgba(14, 114, 166, 1);
    --mdc-checkbox-unselected-focus-icon-color: rgba(156, 163, 175, 1);
    --mdc-checkbox-unselected-hover-icon-color: rgba(156, 163, 175, 1);
    --mdc-checkbox-disabled-selected-icon-color: rgba(14, 113, 166, 0.5);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(156, 163, 175, 1);
    --mdc-checkbox-unselected-icon-color: rgba(156, 163, 175, 1);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(156, 163, 175, 1);
}

.mdc-checkbox__ripple {
    display: none !important;
}

.mdc-checkbox__background {
    @apply rounded-lg;
}
