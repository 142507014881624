.brand-input-box {
    @apply w-full flex flex-row items-center gap-2 bg-brand-light text-gray-900 rounded-lg transition-all hover:bg-white/60 border;

    &.invalid {
        @apply border border-red-500;

        input {
            @apply text-red-500 #{!important};
        }

        ::placeholder {
            @apply text-red-500 #{!important};
        }

        > ng-icon:first-of-type {
            @apply text-red-500 #{!important};
        }
    }

    > ng-icon {
        @apply text-2xl transition-all delay-100;
        &:first-of-type {
            @apply translate-x-6 pointer-events-none touch-none;
        }
        &:not(:first-of-type):last-of-type {
            @apply -translate-x-6;
        }
    }

    input {
        @apply w-full bg-transparent text-core-accent px-6 py-3;
    }

    ::-webkit-file-upload-button {
        @apply cursor-pointer;
    }

    &.content,
    .content {
        @apply px-6 py-3;
    }

    ::placeholder {
        @apply text-gray-900;
    }
}

input {
    &:focus-visible {
        outline: none;
    }
}

// FIXME: Improve styling also for better caret UI
.input-select {
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    padding: 4px 6px;
    min-width: 164px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" stroke-width="1.25" stroke="gray" class="w-4 h-2"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></svg>');
    background-position-x: 100%;
    background-position-y: 3px;
}
