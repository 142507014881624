.mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: rgba(14, 114, 166, 1);
    --mdc-switch-selected-handle-color: rgba(14, 114, 166, 1);
    --mdc-switch-selected-hover-state-layer-color: rgba(14, 114, 166, 1);
    --mdc-switch-selected-pressed-state-layer-color: rgba(14, 114, 166, 1);
    --mdc-switch-selected-focus-handle-color: rgba(14, 114, 166, 1);
    --mdc-switch-selected-hover-handle-color: rgba(14, 114, 166, 1);
    --mdc-switch-selected-pressed-handle-color: rgba(14, 114, 166, 1);
    --mdc-switch-selected-focus-track-color: rgba(71, 114, 166, 1);
    --mdc-switch-selected-hover-track-color: rgba(71, 114, 166, 1);
    --mdc-switch-selected-pressed-track-color: rgba(71, 114, 166, 1);
    --mdc-switch-selected-track-color: rgba(71, 114, 166, 1);
}
