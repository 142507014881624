.mat-mdc-menu-item {
    @apply pl-2 #{!important};
}

.mat-mdc-menu-content {
    @apply p-0 h-full #{!important};
}

// TODO: Decide if we prefer apply the style to the .mat- classes overriding them
.context-menu {
    border: 1px solid #E5E7EB;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06) !important;
    border-radius: 8px !important;
}
