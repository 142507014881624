.mat-mdc-slider.mat-primary.brand-core-slider {
    --mdc-slider-active-track-color: #0E72A6;
    --mdc-slider-inactive-track-color: #A3A3A3;
    --mdc-slider-handle-color: #0E72A6;
    --mdc-slider-focus-handle-color: #0E72A6;
    --mdc-slider-disabled-active-track-color: #0E72A6;
    --mdc-slider-disabled-handle-color: #0e71a6;
}

mat-slider.big {
    @apply max-sm:min-w-[50vw] max-sm:h-6 max-sm:left-1 max-sm:top-0 max-sm:mr-2 #{!important};
}

mat-slider.gold-big {
    @apply max-sm:min-w-[20vw] max-sm:h-6 max-sm:left-1 max-sm:top-0 #{!important};
}

mat-slider.brand-core-slider {
    @apply min-w-[20vw] md:min-w-[48px] h-6 left-1 top-0 m-0;

    .mdc-slider__track {
        .mdc-slider__tick-marks .mdc-slider__tick-mark--active {
            @apply w-3 h-3 bg-[#0E72A6] opacity-100 -left-1 #{!important};
        }

        .mdc-slider__tick-marks .mdc-slider__tick-mark--inactive {
            @apply w-3 h-3 bg-gray-200 opacity-100 -left-1 #{!important};
        }
    }

    .mdc-slider__thumb {
        @apply h-6;
    }

    .mat-mdc-slider-visual-thumb .mat-ripple {
        @apply hidden;
    }
}


.mat-mdc-slider.mat-primary.brand-gold-slider {
    --mdc-slider-active-track-color: #F59E0B;
    --mdc-slider-handle-color: #F59E0B;
    --mdc-slider-inactive-track-color: #A3A3A3;
    --mdc-slider-focus-handle-color: #F59E0B;
    --mdc-slider-disabled-active-track-color: #F59E0B;
    --mdc-slider-disabled-handle-color: #F59E0B;
}

.mat-mdc-slider.mat-primary.brand-gold-slider-full {
    --mdc-slider-handle-color: #F59E0B;
}


mat-slider.brand-gold-slider {
    @apply min-w-[48px] h-6 left-1 top-0 m-0;

    .mdc-slider__thumb {
        @apply h-6;
    }

    .mat-mdc-slider-visual-thumb .mat-ripple {
        @apply hidden;
    }
}

input[type=range]:focus-visible {
    opacity: 0 !important;
}

.mat-mdc-slider.mat-primary:has(input[type=range]:focus-visible) {

    .mdc-slider__thumb-knob {
        @apply outline outline-2 outline-[rgba(26,255,179,1)] #{!important};
    }
}
