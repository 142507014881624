@tailwind base;
@tailwind components;
@tailwind utilities;

// Material Theme
@import "assets/scss/theme";

// Utils and Brand UI Elements
@import "assets/scss/input";
@import "assets/scss/mat-bottom-sheet";
@import "assets/scss/mdc-checkbox";
@import "assets/scss/mdc-menu";
@import "assets/scss/mdc-slide-toggle";
@import "assets/scss/mdc-slider";
@import "assets/scss/overlay";
@import "assets/scss/scrollbar";

// Google Fonts and Icons
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap);
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

html,
body {
    height: 100%;
}

body {
    @apply bg-brand;
}
